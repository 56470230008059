import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import './Timeline.css';

const Timeline = () => {
    return (
        <div className="timeline">
            <h3 className="header">
            Every single moment I spend with you is a moment that I never want to forget.
            In that spirit, I made this website to document all the incredible chapters of our relationship. 
            It's a digital timeline of us filled with our memories, happiest moments, and the countless reasons why I love you. ❤️
            </h3>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="March 2022"
                    icon={<FontAwesomeIcon classname="icon" icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/IMG_0968.png" alt="looking" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Looking For You</h3>
                    <p>
                        This was when these other hoes were out here breaking my heart. I was in desperate search for a good girl to save me from the streets...
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="April 2022"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 4 of 44.jpeg" alt="found" />
                    </div>

                    <h3 className="vertical-timeline-element-title">You Found Me</h3>
                    <p>
                        100% your face when you saw me walk through the front door at lakehouse 2022.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="May 2022"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <video id="myVideo" width="400" height="200" controls>
                        <source src="photos/video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <h3 className="vertical-timeline-element-title">First Time I Bought You Alcohol</h3>
                    <p>
                        My first attempt to seduce you which was foiled and left me in shambles.
                    </p>
                </VerticalTimelineElement>



                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="October 2022"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 5 of 44.jpeg" alt="camping" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Not Much Sleeping</h3>
                    <p>
                        Big time skip but this is when you were in love with me at camping and we had stayed up the entire night before.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="October 2022"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 7 of 44.jpeg" alt="txou1" />
                    </div>

                    <h3 className="vertical-timeline-element-title">TX/OU 2022</h3>
                    <p>
                        We were sitting next to each other and you were hyperaware of it.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="November 2022"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/All Photos - 1 of 1.jpeg" alt="via313" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Date #2</h3>
                    <p>
                        Via 313, our second "official" date. Pizza was amazing and I'm still partial to Via because of us.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="December 2022"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 8 of 44.jpeg" alt="boatformal" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Boat Formal</h3>
                    <p>
                        Me: "Will you be my date to boat formal?"
                    </p>
                    You: "No"

                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="December 2022"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 10 of 44.jpeg" alt="trail" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Austin Trail of Lights</h3>
                    <p>
                        Holding you and spinning under the tree to self-induce seizures was top tier.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="January 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 11 of 44.jpeg" alt="bdayparty" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Anjali's 22nd Bollywood Theme</h3>
                    <p>
                        The only Aishwarya Rai I (Hrithik Roshan) could ever ask for.
                        Also ther first time we danced to Dhoom together.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="February 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 12 of 44.jpeg" alt="valentines" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Valentine's Day</h3>
                    <p>
                        Pizza making and wine/movie night with my valentine.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="February 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 13 of 44.jpeg" alt="sixth" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Dirty 6th With My Friends</h3>
                    <p>
                        Love this picture because you look so good and my hand placement is on point.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="March 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 14 of 44.jpeg" alt="holi" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Holi</h3>
                    <p>
                        First picture of me kissing you.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="March 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 17 of 44.jpeg" alt="cabo1" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Cabo</h3>
                    <p>
                        Peak of life, so many memories including you dragging me into the ocean.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="March 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 16 of 44.jpeg" alt="cabo2" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Cabo pt. 2</h3>
                    <p>
                        The boat! Incredible experience with everyone who came.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="March 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 20 of 44.jpeg" alt="cabo3" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Cabo pt. 3</h3>
                    <p>
                        Loved this time with me, you, Parth, Anjali, and Simone on the beach.
                        One of my favorite moments of the trip and we got cute pictures.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="March 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 22 of 44.jpeg" alt="deluccas" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Delucca's!</h3>
                    <p>
                        Nothing is sexier than seeing my girl down 20 slices of pizza.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="March 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 24 of 44.jpeg" alt="mybday" />
                    </div>

                    <h3 className="vertical-timeline-element-title">My 22nd Birthday</h3>
                    <p>
                        Featuring your dream sexual encounter (other than 2 big black dudes kissing).
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="April 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 25 of 44.jpeg" alt="sendoff" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Senior Sendoff</h3>
                    <p>
                        Wholesome day, I remember you gave a touching speech and overall bittersweet.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="May 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 26 of 44.jpeg" alt="grad" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Your Grad Pics</h3>
                    <p>
                        Dev: "Okay guys, pretend you like each other now!"
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="May 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 29 of 44.jpeg" alt="grad1" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Graduation pt. 1</h3>
                    <p>
                        Your big day! Featuring flowers and stuffed teddy bear.
                        Also your parent's first time seeing me.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="May 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 30 of 44.jpeg" alt="grad2" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Graduation pt. 2</h3>
                    <p>
                        My time to shine, plus postgame dinner with my and Rushi's families.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="May 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 32 of 44.jpeg" alt="nola" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Senior Trip: New Orleans</h3>
                    <p>
                        Rose: Our side quest to get fried chicken when you were super drunk.
                    </p>
                    <p>
                        Thorn: You getting mad at me for falling asleep too quickly.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="September 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 34 of 44.jpeg" alt="cin11" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Cincinnati 1 pt. 1</h3>
                    <p>
                        Matching sunglasses bc we are that couple.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="September 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 35 of 44.jpeg" alt="cin12" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Cincinnati 1 pt. 2</h3>
                    <p>
                        Tripping at the zoo but feeling safe because I had you with me.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="September 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 37 of 44.jpeg" alt="icehouse" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Ice House on the Katy Trail</h3>
                    <p>
                        Fun day where we went to my local watering hole.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="September 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 38 of 44.jpeg" alt="cin21" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Cincinnati 2 pt. 1</h3>
                    <p>
                        Oktoberfest!
                        The BIGGEST celebration in the USA, was amazing to try so many different drinks and food items.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="September 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 39 of 44.jpeg" alt="cin22" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Cincinnati 2 pt. 2</h3>
                    <p>
                        Pepp and Dolores, one of our first finer dining excursions.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="October 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 41 of 44.jpeg" alt="txou2" />
                    </div>

                    <h3 className="vertical-timeline-element-title">TX/OU 2023</h3>
                    <p>
                        Texas L (sad) but awesome weekend spending it with everyone!
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="October 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 43 of 44.jpeg" alt="cin3" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Cincinnati 3</h3>
                    <p>
                        Horse racing, drinking, and awesome thai food. Most importantly, time together.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="November 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 44 of 44.jpeg" alt="dtxbot" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Dallas Botanical Garden</h3>
                    <p>
                        The 12 Days of Christmas and the village scavenger hunt.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="December 2023"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 1 of 44.jpeg" alt="xmas23" />
                    </div>

                    <h3 className="vertical-timeline-element-title">Christmas 2023</h3>
                    <p>
                        On the third day of Christmas, my true love gave to me, a three-in one charger, two martini glasses, a new UT polo, and a 2 liter bottle of Grey Goose!
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date="January 2024"
                    icon={<FontAwesomeIcon icon={faHeart} />}
                    iconStyle={{ color: "#f00" }}
                >
                    <div className='timeline-image'>
                        <img src="photos/1Yr - 2 of 44.jpeg" alt="ny24" />
                    </div>

                    <h3 className="vertical-timeline-element-title">New Years</h3>
                    <p>
                        My first News Years kiss ever, cheers to a great next year ahead for us.
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    );
};

export default Timeline;
