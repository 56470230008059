import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './Countdown.css';

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const now = moment();
    const target = moment(targetDate);
    const duration = moment.duration(target.diff(now));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft);
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <div className="countdown-container">
      <div className="countdown-item">
        <div className="countdown-label">Days</div>
        <div className="countdown-value">{timeLeft.days}</div>
      </div>
      <div className="countdown-item">
        <div className="countdown-label">Hours</div>
        <div className="countdown-value">{timeLeft.hours}</div>
      </div>
      <div className="countdown-item">
        <div className="countdown-label">Minutes</div>
        <div className="countdown-value">{timeLeft.minutes}</div>
      </div>
      <div className="countdown-item">
        <div className="countdown-label">Seconds</div>
        <div className="countdown-value">{timeLeft.seconds}</div>
      </div>
    </div>
  );
};

export default Countdown;
