import React, { useState } from 'react';
import Timeline from './components/Timeline';
import PasswordModal from './components/PasswordModal';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const configPassword = process.env.REACT_APP_SITE_PASSWORD;

  const handlePasswordSubmit = (password) => {
    if (password === configPassword) {
      setIsAuthenticated(true);
      setIsModalOpen(false);
    } else {
      alert('Incorrect password. Try again.');
    }
  };

  return (
    <div>
      <PasswordModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onPasswordSubmit={handlePasswordSubmit}
      />

      {isAuthenticated && (
        <Timeline />
      )}
    </div>
  );
};

export default App;
