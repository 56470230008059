import React, { useState } from 'react';
import Modal from 'react-modal';
import Countdown from './Countdown';
import './PasswordModal.css'; 

const PasswordModal = ({ isOpen, onRequestClose, onPasswordSubmit }) => {
  const [password, setPassword] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onPasswordSubmit(password);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Password Required"
      className="password-modal"
    >
      <h2>Password Required</h2>
      <form onSubmit={handleSubmit}>
        <input type="password" value={password} onChange={handlePasswordChange} />
        <button type="submit">Submit</button>
        <Countdown targetDate={'2024-01-25T23:59:59'} />
      </form>
    </Modal>
    
  );
};

export default PasswordModal;
